
import { RiskGroup } from '@/store/modules/company-tabs/peer-benchmarking';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableRiskCell extends Vue {
  @Prop() RiskGroupCurrent!: RiskGroup;
  @Prop() RiskGroup12MonthsAgo!: RiskGroup;

  get riskTypes(): Record<RiskGroup, [number, number, string]> {
    return {
      'Low Risk': [2, 3, '#429bf8'],
      'Medium Risk': [3, 4, '#61ca56'],
      'Medium-High Risk': [4, 5, '#f2b13d'],
      'High Risk': [5, 6, '#ec6d58'],
      'Highest Risk': [6, 7, '#ea4025'],
    };
  }

  countMarginPercent(start: number, end: number) {
    const n = end - start;
    if (n === 1) return 25;
    return (1 / (n * 2)) * 100;
  }
}
