var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"symbol",style:({
    'grid-column-start': Math.min(
      _vm.riskTypes[_vm.RiskGroupCurrent][0],
      _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0]
    ),
    'grid-column-end': Math.max(
      _vm.riskTypes[_vm.RiskGroupCurrent][1],
      _vm.riskTypes[_vm.RiskGroup12MonthsAgo][1]
    ),
    padding: `0 calc(${_vm.countMarginPercent(
      Math.min(_vm.riskTypes[_vm.RiskGroupCurrent][0], _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0]),
      Math.max(_vm.riskTypes[_vm.RiskGroupCurrent][1], _vm.riskTypes[_vm.RiskGroup12MonthsAgo][1])
    )}% - 5px)`,
  })},[_c('div',{class:{
      triangle: _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] <= _vm.riskTypes[_vm.RiskGroupCurrent][0],
      square: _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] > _vm.riskTypes[_vm.RiskGroupCurrent][0],
      [_vm.RiskGroupCurrent.split(' ')[0]]:
        _vm.riskTypes[_vm.RiskGroupCurrent][0] <= _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0],
      [_vm.RiskGroup12MonthsAgo.split(' ')[0]]:
        _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] <= _vm.riskTypes[_vm.RiskGroupCurrent][0],
    }}),_c('div',{staticClass:"line",style:({
      background: `linear-gradient(90deg, ${
        _vm.riskTypes[_vm.RiskGroupCurrent][0] <= _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0]
          ? _vm.riskTypes[_vm.RiskGroupCurrent][2]
          : _vm.riskTypes[_vm.RiskGroup12MonthsAgo][2]
      } 0%, ${
        _vm.riskTypes[_vm.RiskGroupCurrent][1] >= _vm.riskTypes[_vm.RiskGroup12MonthsAgo][1]
          ? _vm.riskTypes[_vm.RiskGroupCurrent][2]
          : _vm.riskTypes[_vm.RiskGroup12MonthsAgo][2]
      } 100%)`,
    })}),_c('div',{class:{
      triangle: _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] > _vm.riskTypes[_vm.RiskGroupCurrent][0],
      'triangle-reverse': _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] > _vm.riskTypes[_vm.RiskGroupCurrent][0],
      square: _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] <= _vm.riskTypes[_vm.RiskGroupCurrent][0],
      [_vm.RiskGroupCurrent.split(' ')[0]]:
        _vm.riskTypes[_vm.RiskGroupCurrent][0] >= _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0],
      [_vm.RiskGroup12MonthsAgo.split(' ')[0]]:
        _vm.riskTypes[_vm.RiskGroup12MonthsAgo][0] >= _vm.riskTypes[_vm.RiskGroupCurrent][0],
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }