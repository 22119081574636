
import { PeerBenchmarkingTableItem } from '@/store/modules/company-tabs/peer-benchmarking';
import { Component, Vue, Prop } from 'vue-property-decorator';
import TableRiskCell from './TableRiskCell.vue';

@Component({ components: { TableRiskCell } })
export default class PeerBenchmarkingTable extends Vue {
  @Prop() tableData!: PeerBenchmarkingTableItem[];
  @Prop() currentCompany!: PeerBenchmarkingTableItem;

  get headings() {
    return [
      { value: this.currentCompany.LowRiskBreachProbability, title: 'low' },
      { value: this.currentCompany.MediumRiskBreachProbability, title: 'medium' },
      { value: this.currentCompany.MediumHighRiskBreachProbability, title: 'medium-high' },
      { value: this.currentCompany.HighRiskBreachProbability, title: 'high' },
      { value: this.currentCompany.HighestRiskBreachProbability, title: 'highest' },
    ];
  }

  get best() {
    return this.tableData.filter(({ RankGroup }) => RankGroup === 'Best 25%');
  }

  get middle() {
    return this.tableData.filter(({ RankGroup }) => RankGroup === 'Middle 50%');
  }

  get worst() {
    return this.tableData.filter(({ RankGroup }) => RankGroup === 'Worst 25%');
  }
}
