
import TriangleTextBlock from '@/components/TriangleTextBlock.vue';
import { RiskPostureAnalysisItem } from '@/store/modules/company-tabs/risk-posture-analysis';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ components: { TriangleTextBlock } })
export default class RiskPostureAnalysisScheme extends Vue {
  @Prop() data!: RiskPostureAnalysisItem;

  get captions() {
    return [
      'had evidence of active reconnaissance',
      'had active DW forums targeting companies',
      'had active markets selling credentials, entry point access',
      'had evidence of recent stolen credentials in use',
      'had evidence of malicious traffic (i.e. recon, etc)',
      'had active increasing trends across malicious sources',
      'had high traffic with malicious sources and machines',
      'rising trend in risk factors, suggesting successful attacking strategies across all group',
    ].reverse();
  }
}
