var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"triangle-text-block-container",style:({ height: `${_vm.height}px` })},[_c('div',{staticClass:"text",style:({
      'padding-left': `${_vm.type === 'end' ? _vm.width : 0}px`,
      'background-color': _vm.color,
    })},[(_vm.type !== 'end')?_c('div',{staticClass:"triangle",style:({
        'border-width': `${_vm.height * 0.5}px 0 ${_vm.height * 0.5}px ${_vm.width}px`,
        'border-color': `transparent transparent transparent ${_vm.altColor}`,
      })}):_vm._e(),_vm._t("default")],2),(_vm.type !== 'start')?_c('div',{staticClass:"triangle",style:({
      'border-width': `${_vm.height * 0.5}px 0 ${_vm.height * 0.5}px ${_vm.width}px`,
      'border-color': `transparent transparent transparent ${_vm.color}`,
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }