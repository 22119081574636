
import { Component, Vue } from 'vue-property-decorator';
import RiskPostureAnalysisScheme from './elements/RiskPostureAnalysisScheme.vue';
import { RiskPostureAnalysisModule } from '@/store/modules/company-tabs/risk-posture-analysis';
import PeerBenchmarkingTable from './elements/PeerBenchmarkingTable.vue';
import { PeerBenchmarkingModule } from '@/store/modules/company-tabs/peer-benchmarking';

@Component({
  components: { PeerBenchmarkingTable, RiskPostureAnalysisScheme },
})
export default class PeerBenchmarkingMock extends Vue {
  get companyTitle() {
    return this.$store.getters.companyFullName as string;
  }

  get tableData() {
    return PeerBenchmarkingModule.tableData;
  }

  get current() {
    return PeerBenchmarkingModule.currentItem;
  }

  get riskData() {
    return RiskPostureAnalysisModule.item;
  }

  get peerNames() {
    return PeerBenchmarkingModule.peerCompanyList;
  }
}
