
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TriangleTextBlock extends Vue {
  @Prop() height!: number;
  @Prop() width!: number;
  @Prop() color!: string;
  @Prop() altColor!: string;
  @Prop({ default: 'end' }) type!: 'end' | 'start-end' | 'start';
}
